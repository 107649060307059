.description{
    margin-top: 20px;
    padding-top: 20px;
    padding-left: 15px;
    padding-right: 15px;
    color: #424d61;
    background-color: #f5f5f5;
    border: rgb(225, 216, 216);
    height: 40vh;
    overflow: auto;
    display: flex;
    margin-bottom: 20px;
    flex-direction: column;
  }

  .description::-webkit-scrollbar {
    width: 12px; /* Set the width of the scrollbar */
  }
  
  .description::-webkit-scrollbar-thumb {
    background-color: transparent; /* Make the thumb (moving part) transparent */
  }
  

  .desHeaderStyle{
    /* font-weight: 500;
    font-size: 20px;
    font-family: Arial, Helvetica, sans-serif; */
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    padding-bottom: 20px;
    padding-top: 22px;

  }
  .desParaStyle{
    /* font-family: Arial, Helvetica, sans-serif; */
    font-family: Helvetica,Arial!important;
    color: #424d61;
    font-size: 14px;
    line-height: 1.42857143;
    padding-bottom: 12px;

  }