.home{
    /* background-color: blue; */
    display: flex;
    /* padding-left: 300px;
    padding-right: 200px; */
    /* flex-direction: row */
    max-width: 1147px;
    margin: 0 auto;
}
@media only screen and (max-width: 1200px) {
  .home {
    padding-left: 80px;
    padding-right: 80px;
  }
}
@media only screen and (max-width: 900px) {
    .home {
      padding-left: 50px;
      padding-right: 50px;
    }
  }
  
  @media only screen and (max-width: 720px) {
    .home {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  
.midHome{
    /* background-color: aquamarine; */
    display: flex;
    justify-content: space-between;
}

.headResult{
    background-color: #d9edf7;
    padding-left: 30px;
    padding-top: 5px;
    padding-bottom: 20px;
    /* height: 50px; */
    justify-content: center;
    font-size:36px;
    font-weight: bold;
    border: 1px solid #ddd;
    margin-top: 15PX;
    border-radius: 3px;
    color: #424d61;
    /* padding: 10px; */
    /* margin-top: 3px; */
    /* margin-bottom: 10px; */

}
.textOperation{
  display: flex;
  flex-direction: row;
  justify-content:flex-end;
  align-items: center;
  height: 40px;
  border: 1px solid #ddd;
  background-color: #f5f5f5;
  margin-top: 10PX;
  /* margin-bottom: 10px; */

}
.texteditorIcons{
  /* border: 1px solid black; */
  border: 1px solid #b4a4a4;
  /* padding: 3px; */
  margin-right: 2px;
  margin-left: 10px;
  
}
.textdry{
  margin-left: 0px;
}




.midRight{
    /* background-color: brown; */
    display: flex;
    width: 75%;
    flex-direction: column;
}

.textinput{
    width: 100%;
    height: 55vh;
    resize:none;
    border: 2px solid rgb(209, 200, 200);
    font-size: large;
    cursor:auto;
    padding-left: 2px;
    padding-right: 2px;
}

@media only screen and (max-width: 720px) {
  .textinput {
    height: 30vh;
  }
}

.midLeft{
    display: flex;
    flex-direction: column;
    width: 25%;
    padding-left: 20px;
    padding-top: 15px;
}
@media only screen and (max-width: 1200px) {
  .midLeft {
    padding-left: 0px;
  }
}
.advertisediv{
  /* display: flex; */
  /* width: 50px; */
  /* display: flex;
  align-items: center;
  justify-content: center; */

  max-height: 40vh;
  /* max-width: 40vh; */
}
.accordion {
    /* background-color: #f5f5f5; */
    color: #210c0c;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
    transition: 0.4s;
  }
  
  .active, .accordion:hover {
    background-color: #ccc; 
  }
  
  .panel {
    padding: 0 18px;
    display: none;
    background-color: white;
    overflow: hidden;
  }

  .mirrored-icon{
    transform: scaleX(-1);
    padding-top: 2px;
  }
  .accordionAtrributes{
    border: 1px solid #ddd;
    background-color: rgb(255, 251, 251);
    border-left:none ;
    border-right: none;
    border-bottom: none;
    height: 30px;
    display: flex;
    justify-content: space-between ;
    align-items: center;
  }

  .numbertext-container {
    width: 140px;
    overflow: hidden;
  }

  .numbertext{
    display: flex;
    margin-left: 20px;
    color: #424d61;  
  }
  .scrollanimation{
    white-space: nowrap;
    animation: scrollText 5s linear infinite;
  }

  @keyframes scrollText {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }


  .numberCircle{
    background-color: #635f5f;
    padding: 0px 10px 0px 10px;
    margin-right: 20px;
    border-radius: 10px;
    font-size: 13px;
  }
  
  @media (max-width: 900px) {
    .midHome {
        flex-direction: column;
        width: 100%;
    }
    .midRight{
      width: 100%;
    }
    .midLeft{
      width: 100%;
    }
  
  }

 
  
  /* Optional: Adjust styles for the first and last grids when hidden */
  /* .home > div:first-child,
  .home > div:last-child {
    display: none;
  
    @media (max-width: 900px) {
      display: none; 
    }
  } */

  .faqSection{
    background-color: #f5f5f5;
    color: rgb(100, 94, 94);
    padding: 20px;
    max-height: 50vh;
    /* overflow: auto; */
    display: flex;
    flex-direction: column;
  }

  .defaultwordaccordian{
    font-family: Helvetica,Arial!important;
    font-style: italic;
    font-size: 14px;
    color: #424d61;
  }

  .css-15v22id-MuiAccordionDetails-root {
    padding: 8px 16px 16px;
    max-height: 650px;
    overflow: auto;
}
  