.footer{
    background-color: black;
    height:max-content;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 175px;
    padding-right: 175px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.leftFooter{
    display: flex;
    flex-direction: column;
}
.footerlink{
    color: white;
    text-decoration: none; 
}
.footerlink:hover {
    text-decoration: underline; /* Add underline on hover */
}
.midFooter{
    display: flex;
    flex-direction: column;
}
.rightFooter{
    display: flex;
    flex-direction: column;
}
.iconcolor{
    color: rgb(76, 76, 174);
}

@media only screen and (max-width: 900px) {
    .footer {
      flex-direction: column;
      padding: 30px;
    }
  }

  @import url('https://fonts.googleapis.com/css?family=Rubik:300,400,500,600,700,800,900,300i,400i,500i,600i,700i,800i,900i&display=swap'); 
  body {  
    font-family:sans-serif;
    font-weight:400;
    text-transform:none;
    font-size:17px;
    line-height:1.5;
    color:var(--contrast);
  }  
* { 
    box-sizing: inherit;
} 

body { 
    --wp--preset--color--contrast: var(--contrast); 
    --wp--preset--color--contrast-2: var(--contrast-2); 
    --wp--preset--color--contrast-3: var(--contrast-3); 
    --wp--preset--color--base-2: var(--base-2); 
    --wp--preset--color--base-3: var(--base-3); 
    --wp--preset--color--accent: var(--accent); 
    --wp--preset--color--accent-2: var(--accent-2); 
    --wp--preset--color--global-color-9: var(--global-color-9); 
} 

body { 
    margin: 0; 
    padding: 0; 
    border: 0;
} 

body { 
    font-family: -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"; 
    font-weight: 400; 
    text-transform: none; 
    font-size: 17px; 
    line-height: 1.5;
} 

body { 
    background-color: var(--base-3); 
    color: var(--contrast);
} 

body { 
    font-family: Rubik, sans-serif;
} 

html { 
    margin: 0; 
    padding: 0; 
    border: 0;
} 

html { 
    font-family: sans-serif; 
    -webkit-text-size-adjust: 100%; 
    -ms-text-size-adjust: 100%; 
    -webkit-font-smoothing: antialiased; 
    -moz-osx-font-smoothing: grayscale;
} 

html { 
    box-sizing: border-box;
} 

:root { 
    --contrast: #222222; 
    --contrast-2: #575760; 
    --contrast-3: #b2b2be; 
    --base-2: #f7f8f9; 
    --base-3: #ffffff; 
    --accent: #0C4767; 
    --accent-2: #566E3D; 
    --global-color-9: #142C5F;
} 

:root { 
    --gp-search-modal-bg-color: var(--base-3); 
    --gp-search-modal-text-color: var(--contrast); 
} 

.container-xdi { 
    text-align: left; 
    background-color: #000000;
} 

*,:after,:before { 
    box-sizing: inherit;
} 

.container-2yl { 
    width: 95%; 
    height: 100%; 
    max-width: 1280px; 
    margin-right: auto; 
    margin-left: auto; 
    background-color: #000000;
} 

.container-mwp { 
    padding-top: 40px;
} 

.container-njm { 
    padding-top: 10px;
} 

.wrapper-v41 { 
    display: flex; 
    flex-wrap: wrap;
} 

.container-78e { 
    text-align: center;
} 

.column-6vc { 
    box-sizing: border-box;
} 

.wrapper-v41 > .column-thz  { 
    width: 20%;
} 

.wrapper-v41 > .column-npo  { 
    width: 80%;
} 

a { 
    transition: color .1s ease-in-out,background-color .1s ease-in-out;
} 

a { 
    text-decoration: none;
} 

a { 
    color: var(--accent);
} 

.button-92g { 
    text-decoration: none;
} 

a.button-flc { 
    display: inline-flex; 
    padding: 15px 20px; 
    border-radius: 15px; 
    color: #ffffff;
} 

a:hover { 
    text-decoration: underline;
} 

a:hover,a:active { 
    color: var(--contrast);
} 

a.button-flc:hover,a.button-flc:active { 
    background-color: #222222; 
    color: #ffffff;
} 

a.button-v6k { 
    display: inline-flex; 
    padding: 15px 20px; 
    border-radius: 15px; 
    color: #ffffff;
} 

a.button-v6k:hover,a.button-v6k:active { 
    background-color: #222222; 
    color: #ffffff;
} 

a.button-5en { 
    display: inline-flex; 
    padding: 15px 20px; 
    border-radius: 15px; 
    color: #ffffff;
} 

a.button-5en:hover,a.button-5en:active { 
    background-color: #222222; 
    color: #ffffff;
} 

a.button-dkm { 
    display: inline-flex; 
    padding: 15px 20px; 
    border-radius: 15px; 
    color: #ffffff;
} 

a.button-dkm:hover,a.button-dkm:active { 
    background-color: #222222; 
    color: #ffffff;
} 

a.button-qtz { 
    display: inline-flex; 
    padding: 15px 20px; 
    border-radius: 15px; 
    color: #ffffff;
} 

a.button-qtz:hover,a.button-qtz:active { 
    background-color: #222222; 
    color: #ffffff;
} 

a.button-g3m { 
    display: inline-flex; 
    padding: 15px 20px; 
    border-radius: 15px; 
    color: #ffffff;
} 

a.button-g3m:hover,a.button-g3m:active { 
    background-color: #222222; 
    color: #ffffff;
} 

p { 
    margin: 0; 
    padding: 0; 
    border: 0;
} 

p { 
    margin-bottom: 1.5em;
} 

p.gb-headline-eee { 
    text-align: center; 
    padding-bottom: 30px; 
    margin-bottom: 0px; 
    color: var(--base-3);
} 

strong { 
    font-weight: 700;
} 

:where(figure) { 
    margin: 0 0 1em;
} 

figure { 
    margin: 0; 
    padding: 0; 
    border: 0;
} 

figure { 
    margin: 0;
} 

.block-pza { 
    text-align: center;
} 

p.gb-headline-vv1 { 
    color: var(--base-3);
} 

img { 
    height: auto; 
    max-width: 100%;
} 

.image-j5q { 
    width: 200px; 
    height: 120px;
} 

.block-oik img  { 
    vertical-align: middle;
}