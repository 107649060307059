.askQuestions{
    background-color: rgb(252, 252, 254);
    border: 2px solid rgb(138, 128, 128);
    border-radius: 20px;
    height: 40px;
    min-height: 30px;
    padding-left: 20px;
    margin-top: 20px;
    width: 100%;
    min-width: 100px;
}
.accordianScroll{
    max-height: 30vh;
    overflow: auto;
}


.accordianScroll::-webkit-scrollbar {
    width: 12px; /* Set the width of the scrollbar */
  }
  
  .accordianScroll::-webkit-scrollbar-thumb {
    background-color: transparent; /* Make the thumb (moving part) transparent */
  }
.buttonSend{
    margin-top: 23px;
    font-size: 12px;
}
